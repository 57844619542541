var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "参数名称", prop: "configName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.configName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "configName", $$v)
                  },
                  expression: "form.configName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "参数键名", prop: "configKey" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.configKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "configKey", $$v)
                  },
                  expression: "form.configKey",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "参数键值", prop: "configValue" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.configValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "configValue", $$v)
                  },
                  expression: "form.configValue",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "系统内置", prop: "configType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "是否内置" },
                  model: {
                    value: _vm.form.configType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "configType", $$v)
                    },
                    expression: "form.configType",
                  },
                },
                _vm._l(_vm.typeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入备注",
                  type: "textarea",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }